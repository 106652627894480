@import url(https://fonts.googleapis.com/css2?family=Cagliostro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cagliostro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cagliostro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cagliostro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cagliostro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
.gpt3__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}
.gpt3__blog {
    display: flex;
    flex-direction: column;    
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-container_groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}
.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.gpt3__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gpt3__features-container__feature h2{
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 43px;
    color: #875C80;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.gpt3__features-container__feature-text p{
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #875C80;
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    margin-top: 2rem;
}

.gpt3__features-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #F4D3D4;
}

.gpt3__features-container__feature {
    min-height: 400px;
    margin:0 !important;
    padding: 30px;
}

.gpt3__features-container__feature.feature-color-1{
    background-color: #D4CBD5;
}
.gpt3__features-container__feature.feature-color-2{
    background-color: #B3839C;
}
.gpt3__features-container__feature.feature-color-3{
    background-color: #F4D3D4;
}

.feature-white-text h2{
    color: white;
}

.feature-white-text p{
    color: white;
}

@media screen and (max-width: 990px) {
    .gpt3__features-container{
        flex-direction: column;
    }
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}



/* POSSIBILITYS CSS */

.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.parallax {
  
    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.gpt3__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.3);
}
.gpt3__possibility-content audio{
    margin-top:2rem;
    margin-bottom: 2rem;
}


.gpt3__possibility-content h1, .gpt3__possibility-content h2, .gpt3__possibility-content p{
    color: #fff;
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    }

.gpt3__possibility-content h1 {
    font-size: 45px;
}

.gpt3__possibility-content h2 {
    margin-bottom: 2rem;
    font-size: 30px;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}



/* MEDIA PLAYER */
.gpt3__possibility-content .rhap_container{
    width: 485px;
    background: rgba(255,255,255,0.4);
    box-shadow: none;
    border-radius: 15px;
    padding-right: 20px;
    padding-left: 20px;
}

.gpt3__possibility-content .rhap_container .rhap_volume-indicator{
    background: #875C80;
}

.gpt3__possibility-content .rhap_time{
    color: #875C80;
}
.gpt3__possibility-content .rhap_progress-filled{
    background-color: #875C80;
}
.gpt3__possibility-content .rhap_progress-indicator {
    background-color: white;
}
.rhap_additional-controls .rhap_button-clear svg{
    display: none;
}
.rhap_main-controls svg{
    color: #875C80;
}
.rhap_volume-controls svg{
    color: #875C80;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .rhap_container {
        width:80% !important;
    }
    .gpt3__possibility-content h1 {
        font-size: 33px !important;
        text-align:center !important;
      }
      .gpt3__possibility-content h2 {
        margin-bottom: 2rem !important;
        font-size: 18px !important;
        text-align: center !important;
      }
      .gpt3__navbar-menu svg{
          width:35px !important;
          height:35px !important;
      }
  }


.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
    padding-top:20px;
    padding-bottom: 20px;
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
    color: white;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    border-radius: 5px;
    transition: all 0.3s ease;

    margin-bottom: 10rem;
    cursor: pointer;
}

.gpt3__footer-btn:hover{
    background-color: white;
}

.gpt3__footer-btn:hover p{   
    color: #875C80;
}

.gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
    transition: all 0.3s ease;
}

.gpt3__footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: center;
}

.gpt3__footer-links div {
    width: 100%;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.gpt3__footer-links_logo a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.gpt3__footer-links_logo img {
    width: 48.32px;
    height: 60px;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 15px;
    color: #fff;
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright-links{
    margin-bottom: 1rem;
}

.gpt3__footer-copyright-links a{
 margin: 5px;   
 color: white !important;
 font-size: 16px;
 font-family: var(--font-family);
 text-decoration: underline !important;
}

.gpt3__footer-copyright-links a:hover{
    text-decoration: none !important;
    cursor: pointer;
}

.gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}



a:hover {
    color: unset !important;
}
.gpt3__header {
    display: flex;
    flex-direction: row;
    padding-top: 200px;
}

.gpt3__header-background{
    background-image:url(/static/media/background-img5.ff7806ca.png);
}

.gpt3__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.gpt3__header-content img {
    padding-top: 75px;
    padding-bottom: 50px;
}
.gpt3__header-content h1 {
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 87px;
    text-align: center;
    color: #875C80;
}

.gpt3__header-content p {
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    color: #875C80;
}

.gpt3__header-content__input {
    margin: 2rem 0 1rem;
    display:flex;
    flex-direction: row;
    padding-bottom: 8rem;
}

.gpt3__header-content__input input {
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #875C80;
    border: 2px solid #875C80;
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    width: 200px;
    min-height: 50px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #875C80;
    text-align: center;
    line-height: 25px;
    border: 1px solid #875C80;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;   
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: transparent;
    transition: all 0.3s ease;
}

.gpt3__header-content__input button:hover{
    background:#875C80;
    color: white;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .gpt3__header-content__input {
        padding-bottom:6rem;
    }
    .gpt3__header-content p{
        font-size: 30px;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-content__input {
        padding-bottom:4rem;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
    .gpt3__header-content__input {
        padding-bottom:2rem;
    }
}


.gpt3__header-content__input p{
    width: 100% !important;
    max-width: unset !important;
}

.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: none; /* Umstellen auf Flex damit Login angezeigt wird */
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-sign button{
    transition: all 0.3s ease;   
}

.gpt3__navbar-sign button:hover{
    background: #875C80;
    color: white;
}

.gpt3__navbar-links_container p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #875C80;
    margin: 0 1rem;
    text-align: center;
    line-height: 25px;
    border: 1px solid #875C80;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: all 0.3s ease;   
    width: 113.95px;
    max-width: 113.95px;;
}

.gpt3__navbar-links_container p:hover{
    background: #875C80;
    color: white;
}

.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #875C80;
    margin: 0 1rem;
    text-align: center;
    line-height: 25px;
    border: 1px solid #875C80;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background: transparent;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.parallax {
  
    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.gpt3__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.3);
}
.gpt3__possibility-content audio{
    margin-top:2rem;
    margin-bottom: 2rem;
}


.gpt3__possibility-content h1, .gpt3__possibility-content h2, .gpt3__possibility-content p{
    color: #fff;
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    }

.gpt3__possibility-content h1 {
    font-size: 45px;
}

.gpt3__possibility-content h2 {
    margin-bottom: 2rem;
    font-size: 30px;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}



/* MEDIA PLAYER */
.gpt3__possibility-content .rhap_container{
    width: 485px;
    background: rgba(255,255,255,0.4);
    box-shadow: none;
    border-radius: 15px;
    padding-right: 20px;
    padding-left: 20px;
}

.gpt3__possibility-content .rhap_container .rhap_volume-indicator{
    background: #875C80;
}

.gpt3__possibility-content .rhap_time{
    color: #875C80;
}
.gpt3__possibility-content .rhap_progress-filled{
    background-color: #875C80;
}
.gpt3__possibility-content .rhap_progress-indicator {
    background-color: white;
}
.rhap_additional-controls .rhap_button-clear svg{
    display: none;
}
.rhap_main-controls svg{
    color: #875C80;
}
.rhap_volume-controls svg{
    color: #875C80;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}


.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}
/*
.gradient__bg {
  background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%); 
  background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);  
  background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}
*/

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}

    .video-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 80%;
      width: 100%;
      align-items: center;
      
    }
    .video-tile{
      text-align: center;
      margin-bottom: 30px;
      background: rgba(255, 255, 255, 0.19);
      box-shadow: 6px 6px 35px 10px rgba(0, 0, 0, 0.25);
      padding: 40px;
      height: 100%;
      width: 100%;
      min-height: 330px;
      transition: all .5s ease;
    }
    .video-tile:hover{
      background: rgba(255, 255, 255, 0.4);
    }
    .video-tile:hover h3{
      color: #875C80;
    }
    .video-tile h3{
      color: white;
    }






 @media screen and (min-width: 992px) { 
 .video-container{
  flex-direction: row;
   justify-content: space-between;
 }  
     .video-tile{
      margin-bottom: 0px;
     }
          .video-tile-link{
               width: calc(50% - 15px);
     }

 }

 .video-tile-link:focus{
  outline: 2px solid white !important;
 }


 .video-tile-link:focus .video-tile{
  background: rgba(255, 255, 255, 0.4);
 }

  .video-tile-link:focus .video-tile h3{
    color: #875C80;
 }

 .video-tile-link{
       width: 80%;
   min-height: 330px;
 }

 .video-tile-spacer{
  width: 30px;
 }

 .video-tile svg{
  width: 80px;
 }
 .video-tile h3{
  margin-bottom: 16px;
  transition: all .5s ease;
 }

 .gpt3__possibility-content{
  padding-top: 50px;
  padding-bottom: 50px;
 }
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #FFF 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #fff;
  --color-footer : #845A7F;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

