.gpt3__header {
    display: flex;
    flex-direction: row;
    padding-top: 200px;
}

@import url('https://fonts.googleapis.com/css2?family=Cagliostro&display=swap');

.gpt3__header-background{
    background-image:url('../../assets/background-img5.png');
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.gpt3__header-content img {
    padding-top: 75px;
    padding-bottom: 50px;
}
.gpt3__header-content h1 {
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 87px;
    text-align: center;
    color: #875C80;
}

.gpt3__header-content p {
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    color: #875C80;
}

.gpt3__header-content__input {
    margin: 2rem 0 1rem;
    display:flex;
    flex-direction: row;
    padding-bottom: 8rem;
}

.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #875C80;
    border: 2px solid #875C80;
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    width: 200px;
    min-height: 50px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #875C80;
    text-align: center;
    line-height: 25px;
    border: 1px solid #875C80;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;   
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: transparent;
    transition: all 0.3s ease;
}

.gpt3__header-content__input button:hover{
    background:#875C80;
    color: white;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .gpt3__header-content__input {
        padding-bottom:6rem;
    }
    .gpt3__header-content p{
        font-size: 30px;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-content__input {
        padding-bottom:4rem;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
    .gpt3__header-content__input {
        padding-bottom:2rem;
    }
}


.gpt3__header-content__input p{
    width: 100% !important;
    max-width: unset !important;
}

.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: none; /* Umstellen auf Flex damit Login angezeigt wird */
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-sign button{
    transition: all 0.3s ease;   
}

.gpt3__navbar-sign button:hover{
    background: #875C80;
    color: white;
}

.gpt3__navbar-links_container p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #875C80;
    margin: 0 1rem;
    text-align: center;
    line-height: 25px;
    border: 1px solid #875C80;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: all 0.3s ease;   
    width: 113.95px;
    max-width: 113.95px;;
}

.gpt3__navbar-links_container p:hover{
    background: #875C80;
    color: white;
}

.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #875C80;
    margin: 0 1rem;
    text-align: center;
    line-height: 25px;
    border: 1px solid #875C80;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background: transparent;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
