* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}
/*
.gradient__bg {
  background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%); 
  background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);  
  background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}
*/

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}

    .video-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 80%;
      width: 100%;
      align-items: center;
      
    }
    .video-tile{
      text-align: center;
      margin-bottom: 30px;
      background: rgba(255, 255, 255, 0.19);
      box-shadow: 6px 6px 35px 10px rgba(0, 0, 0, 0.25);
      padding: 40px;
      height: 100%;
      width: 100%;
      min-height: 330px;
      transition: all .5s ease;
    }
    .video-tile:hover{
      background: rgba(255, 255, 255, 0.4);
    }
    .video-tile:hover h3{
      color: #875C80;
    }
    .video-tile h3{
      color: white;
    }






 @media screen and (min-width: 992px) { 
 .video-container{
  flex-direction: row;
   justify-content: space-between;
 }  
     .video-tile{
      margin-bottom: 0px;
     }
          .video-tile-link{
               width: calc(50% - 15px);
     }

 }

 .video-tile-link:focus{
  outline: 2px solid white !important;
 }


 .video-tile-link:focus .video-tile{
  background: rgba(255, 255, 255, 0.4);
 }

  .video-tile-link:focus .video-tile h3{
    color: #875C80;
 }

 .video-tile-link{
       width: 80%;
   min-height: 330px;
 }

 .video-tile-spacer{
  width: 30px;
 }

 .video-tile svg{
  width: 80px;
 }
 .video-tile h3{
  margin-bottom: 16px;
  transition: all .5s ease;
 }

 .gpt3__possibility-content{
  padding-top: 50px;
  padding-bottom: 50px;
 }