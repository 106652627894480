@import url('https://fonts.googleapis.com/css2?family=Cagliostro&display=swap');

.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.gpt3__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gpt3__features-container__feature h2{
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 43px;
    color: #875C80;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.gpt3__features-container__feature-text p{
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #875C80;
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    margin-top: 2rem;
}

.gpt3__features-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #F4D3D4;
}

.gpt3__features-container__feature {
    min-height: 400px;
    margin:0 !important;
    padding: 30px;
}

.gpt3__features-container__feature.feature-color-1{
    background-color: #D4CBD5;
}
.gpt3__features-container__feature.feature-color-2{
    background-color: #B3839C;
}
.gpt3__features-container__feature.feature-color-3{
    background-color: #F4D3D4;
}

.feature-white-text h2{
    color: white;
}

.feature-white-text p{
    color: white;
}

@media screen and (max-width: 990px) {
    .gpt3__features-container{
        flex-direction: column;
    }
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}



/* POSSIBILITYS CSS */


@import url('https://fonts.googleapis.com/css2?family=Cagliostro&display=swap');

.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.parallax {
  
    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.gpt3__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.3);
}
.gpt3__possibility-content audio{
    margin-top:2rem;
    margin-bottom: 2rem;
}


.gpt3__possibility-content h1, .gpt3__possibility-content h2, .gpt3__possibility-content p{
    color: #fff;
    font-family: 'Cagliostro', sans-serif;
    font-style: normal;
    font-weight: normal;
    }

.gpt3__possibility-content h1 {
    font-size: 45px;
}

.gpt3__possibility-content h2 {
    margin-bottom: 2rem;
    font-size: 30px;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}



/* MEDIA PLAYER */
.gpt3__possibility-content .rhap_container{
    width: 485px;
    background: rgba(255,255,255,0.4);
    box-shadow: none;
    border-radius: 15px;
    padding-right: 20px;
    padding-left: 20px;
}

.gpt3__possibility-content .rhap_container .rhap_volume-indicator{
    background: #875C80;
}

.gpt3__possibility-content .rhap_time{
    color: #875C80;
}
.gpt3__possibility-content .rhap_progress-filled{
    background-color: #875C80;
}
.gpt3__possibility-content .rhap_progress-indicator {
    background-color: white;
}
.rhap_additional-controls .rhap_button-clear svg{
    display: none;
}
.rhap_main-controls svg{
    color: #875C80;
}
.rhap_volume-controls svg{
    color: #875C80;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .rhap_container {
        width:80% !important;
    }
    .gpt3__possibility-content h1 {
        font-size: 33px !important;
        text-align:center !important;
      }
      .gpt3__possibility-content h2 {
        margin-bottom: 2rem !important;
        font-size: 18px !important;
        text-align: center !important;
      }
      .gpt3__navbar-menu svg{
          width:35px !important;
          height:35px !important;
      }
  }

